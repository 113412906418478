import React, { useState } from 'react'
import { useWallet } from '../../providers/Wallet'
import { getStakingAddress, getTokenName } from '../../lib/addresses'
import { Contract as EthersContract } from 'ethers/lib/ethers'
import { fp } from '../../lib/math-utils'
import stakingAbi from '../../abi/staking.json'
import { Button, TextInput, useToast } from '@aragon/ui'
import DashboardCard from './DashboardCard'
import logo from '../../assets/redemption_logo.svg'
import MaxButton from './MaxButton'
import { useLocation } from 'react-router-dom'

const Redemption = ({ data, fetch }) => {
  const [amountToRetrieve, setAmountToRetrieve] = useState(0)
  const { ethers, networkName } = useWallet()
  const toast = useToast()
  const search = useLocation().search

  const retrieve = async () => {
    try {
      const signer = ethers.getSigner()
      const stakingAddress = getStakingAddress(networkName, search)
      const contractStaking = await new EthersContract(
        stakingAddress,
        stakingAbi,
        signer
      )

      await contractStaking.redeem(fp(amountToRetrieve))
      setTimeout(() => {
        fetch()
        toast('Retiro realizado')
      }, 10000)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <DashboardCard>
      <div className="header">
        <img alt="" src={logo} />
        Redimir
      </div>
      <div className="content-vertical">
        <TextInput
          wide
          value={Number(amountToRetrieve || 0).toString()}
          onChange={event => {
            setAmountToRetrieve(Number(event.target.value || 0))
          }}
          type="number"
          adornment={
            <MaxButton
              max={data.balanceANu.toFixed(2)}
              onClick={() => setAmountToRetrieve(data.balanceANu)}
            />
          }
          adornmentPosition="end"
        />
        <p>
          a-nu{getTokenName(search)} a redimir{' '}
          <span>$ {amountToRetrieve.toFixed(2)}</span>
        </p>
        <p>
          nu{getTokenName(search)} a recibir{' '}
          <span>$ {(amountToRetrieve * data.price).toFixed(2)}</span>
        </p>
        <Button onClick={retrieve}> Redimir</Button>
      </div>
    </DashboardCard>
  )
}

export default Redemption
