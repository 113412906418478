import { getAddress } from '../hooks/useAddresses'

const getStakingAddress = (networkName, search) => {
  const coin = new URLSearchParams(search).get('coin') || '0'
  return getAddress(networkName)[coin].staking
}

const getTokenAddress = (networkName, search) => {
  const coin = new URLSearchParams(search).get('coin') || '0'
  return getAddress(networkName)[coin].token
}

const getTokenName = search => {
  const coin = new URLSearchParams(search).get('coin') || '0'
  return coin === '0' ? 'ARS' : 'PEN'
}

export { getStakingAddress, getTokenAddress, getTokenName }
