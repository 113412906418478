const ENVIROMENT_ADDRESSES = {
  mainnet: undefined,
  ropsten: undefined,
  rinkeby: undefined,
  bsc: [
    // ars
    {
      staking: '0x3E06DdFa41AAA6736695C6bfC84a8ce60Bd9ae6f',
      token: '0x91bc956F064d755dB2e4EfE839eF0131e0b07E28',
    },
    // pe
    {
      staking: '0xEF5563A72AF2AF5c360b9208b38CF9f3EE0E8941',
      token: '0xA5286319ABbc22cEB755afBD81DF55c1328dAdd1',
    },
  ],
}

export default ENVIROMENT_ADDRESSES
