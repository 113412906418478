import styled from 'styled-components'

const BoxContent = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  background-color: white;
  text-align: center;
  .data-name {
    background: ${props => props.theme.mainColor};
    border-radius: 100px;
    color: white;
    font-family: 'Hind', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    padding: 0 20px;
    min-width: 140px;
    span {
      font-family: 'Hind', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 56px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    span.detail {
      font-family: 'Hind', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
      float: right;
      color: #9ba3b8;
      transform: translate(0px, 10px);
    }
    p {
      font-weight: 100;
      font-size: 10px;
    }
  }
  div.data {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default BoxContent
