import React from 'react'
import BoxContent from './BoxContent'
import DashboardCard from './DashboardCard'
import logo from '../../assets/balance_logo.svg'
import { getTokenName } from '../../lib/addresses'
import { useLocation } from 'react-router-dom'

const Balance = ({ data }) => {
  const search = useLocation().search

  return (
    <DashboardCard>
      <div className="header">
        <img alt="" src={logo} />
        Tu Balance
      </div>
      <BoxContent>
        <div className="data">
          <h1>
            <span>${data.balanceNu.toFixed(0)}</span>
            <br />
            <div className="data-name" style={{ minWidth: '8em' }}>
              Saldo nu{getTokenName(search)}
            </div>
          </h1>
          <h1 style={{ marginTop: '-27px' }}>
            <span className="detail">≈ ${data.totalValANu.toFixed(0)}</span>
            <br />
            <span>${data.balanceANu.toFixed(0)}</span>
            <div className="data-name" style={{ minWidth: '8em' }}>
              Saldo a-nu{getTokenName(search)}
            </div>
          </h1>
        </div>
      </BoxContent>
    </DashboardCard>
  )
}

export default Balance
