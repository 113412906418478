import styled from 'styled-components'

const DashboardCard = styled.div`
  display: block;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;

  .header {
    font-family: 'Hind', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    background-color: ${props => props.theme.mainColor};
    border-top-right-radius: 1rem;
    color: white;
    text-align: left;
    padding: 0.5rem 0.5rem 0.5rem 2rem;

    img {
      height: 32px;
      width: 32px;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }

  .content-vertical {
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
    padding: 2rem 10rem;

    input {
      padding: 1.5rem;
      display: block;
      width: 100%;
      margin-bottom: 1rem;
      background-color: #e8ecf2;
      border-radius: 14px;
      font-family: 'Hind', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 40px;
      color: #333333;
      height: 60px;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-family: 'Hind', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 38px;
      serifcolor: #333333;
      text-align: left;

      span {
        float: right;
      }
    }

    button {
      margin-top: 1rem;
      align-content: center;
      background: #fdc061;
      border-radius: 40px;
      color: white;
      font-family: 'Hind', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 35px;
    }

    button.max {
      background: ${props => props.theme.mainColor};
      border-radius: 22px;
      font-size: 20px;
      line-height: 32px;
      margin-top: -10px;
      height: 1.5rem;
      padding: 0 8px;
    }
    p.max {
      font-family: 'Hind', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #9ba3b8;
      margin: 0;
      text-align: center;
    }
  }
`

export default DashboardCard
