import React from 'react'
import { Button } from '@aragon/ui'

const MaxButton = ({ max, onClick }) => {
  return (
    <div>
      <Button className="max" onClick={onClick}>
        MAX.
      </Button>
      <p className="max">${max}</p>
    </div>
  )
}

export default MaxButton
