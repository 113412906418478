import { utils, BigNumber } from 'ethers'
import { Decimal } from 'decimal.js'

const SCALING_FACTOR = 1e18

export function bigNum(value) {
  return value ? BigNumber.from(value) : null
}

export function fp(value) {
  return bigNum(
    Decimal(value)
      .mul(SCALING_FACTOR)
      .toString()
  )
}

export function toPct(value) {
  return fp(value).div(100)
}

export function fromPct(value) {
  return formatUnits(value.mul(100))
}

export function calculateApy(value) {
  return fp(value)
    .div(100)
    .toString()
}

export function formatUnits(value, { digits = 18, precision = 4 } = {}) {
  if (value.lt(0) || digits < 0) return ''

  const formattedValue = utils.formatUnits(value.toString(), digits)
  return Decimal(formattedValue)
}
