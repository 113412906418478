import styled from 'styled-components'

const Card = styled.div`
  padding: 45px;
  min-width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: white;
  border: 1px solid #e6e5e5;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
`

export default Card
