import React from 'react'
import { ButtonIcon, GU, IconMenu, DropDown } from '@aragon/ui'
import AccountModule from '../Account/AccountModule'
import HeaderLogo from './HeaderLogo'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'

const Header = React.memo(function Header({ compactMode }) {
  const search = useLocation().search
  const coin = new URLSearchParams(search).get('coin') || 0
  const history = useHistory()

  const navigateTo = url => {
    history.push(url)
    history.go(0)
  }

  return (
    <header
      css={`
        position: relative;
        z-index: 3;
        height: ${8 * GU}px;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.05) 0 2px 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
      `}
    >
      <div
        css={`
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 ${3 * GU}px;
        `}
      >
        <div>
          {compactMode ? (
            <ButtonIcon label="Open menu">
              <IconMenu />
            </ButtonIcon>
          ) : (
            <HeaderLogo />
          )}
        </div>
        <CoinDropdown>
          <DropDown
            items={['Contrato a-nuARS', 'Contrato a-nuPEN']}
            selected={coin}
            onChange={coin => navigateTo(`/?coin=${coin}`)}
          />
        </CoinDropdown>
      </div>

      <div
        css={`
          flex-grow: 0;
          display: flex;
          height: 100%;
          padding: 0 ${3 * GU}px;
        `}
      >
        <div
          css={`
            display: flex;
            height: 100%;
          `}
        >
          <AccountModule />
        </div>
      </div>
    </header>
  )
})

const CoinDropdown = styled.div`
  margin-left: 2rem;
  button {
    border-width: 0;
    background: #454545;
    border-radius: 22px;
    color: white;
    font-family: 'Hind', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    svg {
      color: white;
    }
  }
`

export default Header
