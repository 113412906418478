import React from 'react'
import BoxContent from './BoxContent'
import DashboardCard from './DashboardCard'
import logo from '../../assets/details_logo.svg'
import { getTokenName } from '../../lib/addresses'
import { useLocation } from 'react-router-dom'

const Details = ({ data }) => {
  const search = useLocation().search

  return (
    <DashboardCard style={{ marginRight: '3rem' }}>
      <div className="header">
        <img alt="" src={logo} />
        Detalles a-nu{getTokenName(search)}
      </div>
      <BoxContent>
        <h1>
          <span>{data.apy.toFixed(2)}%</span>
          <br />
          <div className="data-name">APY</div>
        </h1>
        <h1>
          <span>${data.price.toFixed(4)}</span>
          <br />
          <div className="data-name">Precio</div>
        </h1>
      </BoxContent>
    </DashboardCard>
  )
}

export default Details
